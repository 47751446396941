body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: 'Impactreg';
  src: local('Impactreg'), url("./fonts/impactreg.woff2") format('woff2');
}
@font-face {
  font-family: 'Oxanium-Bold';
  src: local('Oxanium-Bold'), url("./fonts/Oxanium-Bold.woff") format('woff');
}
@font-face {
  font-family: 'Oxanium-Regular';
  src: local('Oxanium-Regular'), url("./fonts/Oxanium-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Teko-bold-webfont';
  src: local('Teko-bold-webfont'), url("./fonts/teko-bold-webfont.woff2") format('woff2');
}
@font-face {
  font-family: 'Teko-medium-webfont';
  src: local('Teko-medium-webfont'), url("./fonts/teko-medium-webfont.woff2") format('woff2');
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;
  border: none !important;;
  /*border: 1px solid transparent;*/
}